<template>
  <div>
    <ManageSummary />
  </div>
</template>
<script>
import ManageSummary from "@/components/Summary/ManageSummary";
export default {
  components: {
    ManageSummary,
  },
  created() {},
};
</script>
c
